export const visitorIdentity = async () => {
    try {
        const fpPromise = import('@fingerprintjs/fingerprintjs').then((FingerprintJS) => FingerprintJS.load());
        const fp = await fpPromise;
        const result = await fp.get();
        return result.visitorId;
    } catch (e) {
        console.log(e);
        return '';
    }
};

import React from "react";

export default function Netherland(props: React.SVGAttributes<{}>) {
    return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
             viewBox="0 0 130 120" enableBackground="new 0 0 130 120" xmlSpace="preserve" fill="#000000"
             {...props}>
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
                {' '}
                <g id="Infos">
                    {' '}
                    <rect id="BG" x="-1550" y="-880" fill="#D8D8D8" width="2180" height="1700"></rect>
                    {' '}
                </g>
                {' '}
                <g id="Others">
                </g>
                {' '}
                <g id="Europe">
                    {' '}
                    <g id="Row_5">
                    </g>

                    <g id="Row_4">
                    </g>
                    {' '}
                    <g id="Row_3">
                        {' '}
                        <g>
                            {' '}
                            <rect y="0" fill="#DC4437" width="130" height="39"></rect>

                            <rect y="39" fill="#FFFFFF" width="130" height="42"></rect>

                            <rect y="81" fill="#2A66B7" width="130" height="39"></rect>
                            {' '}
                        </g>
                        {' '}
                    </g>
                    {' '}
                    <g id="Row_2">
                    </g>

                    <g id="Row_1">
                    </g>
                    {' '}
                </g>
                {' '}
            </g>
        </svg>
    );
}

import {toast} from 'react-toastify';
import {ToastId} from "@app/constants/ToastId";
import {TFunction} from "react-i18next";
import environments from "@app/configs/environments";
import {getFileSize} from "@app/utils/pdfUtils";

export const statusErrorMessageHandler = (status: number, t: TFunction) => {
    switch (status) {
        case 401: {
            toast(`${t('toast.statusError.401')}`, {type: 'error', toastId: ToastId.ERROR_TOAST});
            break;
        }
        case 405: {
            toast(`${t('toast.statusError.405')}`, {type: 'error', toastId: ToastId.ERROR_TOAST});
            break;
        }
        case 413: {
            toast(`${t('toast.statusError.413')} ${getFileSize(environments.MAX_FILE_SIZE)}`, {type: 'error', toastId: ToastId.ERROR_TOAST});
            break;
        }
        case 415: {
            toast(`${t('toast.statusError.415')}`, {type: 'error', toastId: ToastId.ERROR_TOAST});
            break;
        }
        case 422: {
            toast(`${t('toast.statusError.422')}`, {type: 'error', toastId: ToastId.ERROR_TOAST});
            break;
        }
        case 500: {
            toast(`${t('toast.statusError.500')}`, {type: 'error', toastId: ToastId.ERROR_TOAST});
            break;
        }
        default:
            break;
    }
};

import React from 'react';

import {Avatar, AvatarOwnProps, SxProps, Theme} from '@mui/material';
import cn from 'classnames';

interface IProfileImageProps extends AvatarOwnProps {
    size?: number;
    image?: string | null;
    name: string;
    typography?: string;
    className?: string;
    style?: SxProps<Theme>;
}

ProfileImage.defaultProps = {
    size: 40
};

export default function ProfileImage({
                                         size,
                                         image,
                                         name = ' ',
                                         className = '',
                                         typography = 'h4',
                                         style = {},
                                         variant = "circular"
                                     }: IProfileImageProps) {
    if (image) return <Avatar sx={{...style}} variant={variant} src={image}
                              className={`!mr-0 !h-[${size}px] !w-[${size}px] overflow-hidden  ${className}`}/>;

    return (
        <Avatar sx={{...style}} variant={variant}
                className={`overflow-hidden !h-[${size}px] !w-[${size}px] bg-[#B8D5FF] ${className}`}>
            <span className={cn('text-white', typography)}>{name[0]?.toUpperCase()}</span>
        </Avatar>
    );
}

import React, {useEffect} from 'react';
import MenuDropdown from '@app/components/common/MenuDropdown/MenuDropdown';
import {MenuItem} from '@mui/material';
import {useRouter} from 'next/router';
import {useHandleLogoutMutation} from '@app/store/login/api';
import ProfileImage from '@app/components/auth/profile-image';
import {useBreakpoint} from '@app/lib/hooks/use-breakpoint';
import {useAppDispatch, useAppSelector} from '@app/store/hooks';
import {initialAuthState, selectAuth, setAuth} from '@app/store/auth/authSlice';
import {AuthProps} from '@app/store/auth/types';
import {Logout} from '@app/components/icons/logout-icon';
import {statusErrorMessageHandler} from "@app/utils/errorUtils";
import {capitalize, capitalizeAllFirstAlphabets} from "@app/utils/stringUtils";
import {selectActiveOrganization} from "@app/store/active/slice";
import {useTranslation} from "next-i18next";

function ProfileDropdown({isRoleVisible = true}: { isRoleVisible?: boolean }) {
    const router = useRouter();
    const screenSize = useBreakpoint();
    const {t} = useTranslation(["common"]);
    const auth = useAppSelector(selectAuth);
    const activeOrganization = useAppSelector(selectActiveOrganization);
    const [logout, logoutResponse] = useHandleLogoutMutation();
    const user: AuthProps = auth ?? null;
    const dispatch = useAppDispatch();

    if (logoutResponse.isError) {
        // @ts-ignore
        statusErrorMessageHandler(logoutResponse.error.originalStatus || logoutResponse.error.status, t);
    }
    useEffect(() => {
        if (logoutResponse.isSuccess) {
            dispatch(setAuth(initialAuthState));
            router.reload();
        }
    }, [logoutResponse.isSuccess]);

    const handleLogout = async () => {
        await logout();
    };

    const DropdownItems = [
        {
            icons: Logout,
            text: t('logout'),
            handleButton: handleLogout,
            id: "logout"
        }
    ];
    const newMenuContent = (
        <>
            <ProfileImage size={['xs', '2xs'].indexOf(screenSize) === -1 ? 36 : 28} image={user?.picture}
                          name={user?.name ?? user?.email}/>
            {['xs', '2xs', 'sm', "md"].indexOf(screenSize) === -1 &&
                <div className={"text-start"}>
                    <p className="h5 text-black-800">{capitalizeAllFirstAlphabets(user?.name?.trim() || user?.email || '')}</p>
                    {isRoleVisible &&
                        <p data-testid="organization-role"
                           id={activeOrganization?.userRole}
                           className="p4 text-black-600">{capitalize(activeOrganization?.userRole ? t(`roleType.${activeOrganization?.userRole}`) : "")}</p>}
                </div>}
        </>
    );
    if (user?.isLoading) return <div className="w-9 mt-1 lg:w-[200px] h-12 rounded-[4px] animate-pulse bg-black-300"/>;
    return (
        <MenuDropdown
            showExpandMore={true}
            width={200}
            onClick={(event: any) => {
                event.stopPropagation();
                event.preventDefault();
            }}
            PaperProps={{
                elevation: 0,
                sx: {
                    width: 200,
                    overflow: 'hidden',
                    borderRadius: 2,
                    filter: 'drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.15))',
                    mt: 0.5,
                    padding: 0
                }
            }}
            id="profile-dropdown"
            menuTitle={''}
            menuContent={newMenuContent}
        >
            {DropdownItems.map((dropdownItem) => {
                return (
                    <MenuItem data-testid={dropdownItem.id} key={dropdownItem.text}
                              className="p2  flex gap-2 py-2 text-black-1"
                              onClick={dropdownItem.handleButton}>
                        {React.createElement(dropdownItem.icons, {height: 24, width: 24})}
                        <p>{dropdownItem.text}</p>
                    </MenuItem>
                );
            })}
        </MenuDropdown>
    );
}


export default ProfileDropdown;
